import http from './http_client';
import { errorMessage } from '@/services/messages';
import storage from '@/store/index.js';


export const baseErrHandler = async (callback) => {
  try {
    storage.commit('showLoader');
    await callback();
  } catch (e) {
    console.log(e);
    errorMessage(e?.response?.data?.error?.message);
  } finally {
    storage.commit('hideLoader');
  }
};
export const getCourses = async () => {
  const response = await http.getAuth(`${http.apiUrl()}course/all`);
  const { courses } = response?.data?.data || {};
  return { courses };
};
export const getFilteredCourses = async (data) => {
  const response = await http.post(`${http.apiUrl()}course/show-courses`,data);
  const { courses } = response?.data?.data || {};
  return { courses };
};
export const getCategories = async () => {
  const response = await http.get(`${http.apiUrl()}category/all`);
  const { categories } = response?.data?.data || {};
  return { categories };
};





