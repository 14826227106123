<template>
<LandingPage/>
</template>

<script>

import LandingPage from '@/components/Landing.vue';

export default {
  name: 'App',
  components: {
    LandingPage,

  }
}
</script>

<style lang='scss'>

@import "~toastify-js/src/toastify.css";
body {
  margin: 0;
  background: #131616;
  font-variant-ligatures: none;
  font-family: Axiforma, sans-serif;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.cp {
  cursor: pointer;
}

.btn-black {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid #2F3737;

  color: #959897;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  cursor: pointer;
  background: #131616;
}
.btn-white{
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 4px 4px 16px 0px rgba(19, 22, 22, 0.30);
  color: #131616;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  height: 48px;
}
.btn-white:hover {
  background: #cccccc;
  color: #131616;
}
.btn-gold {
  white-space: nowrap;
  border-radius: 8px;
  background: rgba(240, 216, 0, 1);
  box-shadow: 4px 4px 16px 0px #131616;
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 48px;
  box-sizing: border-box;
  color: rgba(19, 22, 22, 1);
  text-align: center;

  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
}
.btn-red {
  white-space: nowrap;
  border-radius: 8px;
  background: #E50914;
  box-shadow: 4px 4px 16px 0px #131616;
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 48px;
  box-sizing: border-box;
  color: #FFF;
  text-align: center;

  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
}
.btn-red:hover {
  background: rgba(229, 9, 20, 0.84);
  color: rgba(255, 255, 255, 0.85);
}
.btn-pink {
  border-radius: 8px;
  background: #F745A3;
  box-shadow: 4px 4px 16px 0px #131616;
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: #FFF;
  text-align: center;

  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
}

.input-style {
  height: 56px;
  box-sizing: border-box;
  display: flex;
  padding: 12px 16px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 2px solid #2F3737;
  background: #131616;

  caret-color: #949494;
  color: #959897;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.input-style:-webkit-autofill,
.input-style:-webkit-autofill:hover,
.input-style:-webkit-autofill:focus,
.input-style:-webkit-autofill:active
{
  -webkit-box-shadow: 0 0 0 30px #131616 inset !important;
  -webkit-text-fill-color: #959897 !important;
}
.input-style:disabled {
  background: rgb(36, 38, 38);
  color: #959897;
}
.input-style::placeholder {
  color: #959897;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.input-style:focus, textarea {
  outline: none;
}

.input-style:focus-visible {
  border: 2px solid #E50914;;
}
.textarea {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 2px solid #2F3737;
  background: #FFF;
  height: 22px;
  // caret-color: #5729e9;
  resize: none;

}


.input-style2 {
  height: 52px;
  box-sizing: border-box;
  display: flex;
  padding: 0 16px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 0 solid #2F3737;
  background: #252525;
  caret-color: #949494;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #FFFFFF;
  position: relative;
  padding-top: 9px;
}
.input-style2:-webkit-autofill,
.input-style2:-webkit-autofill:hover,
.input-style2:-webkit-autofill:focus,
.input-style2:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #131616 inset !important;
  -webkit-text-fill-color: #959897 !important;
}
.input-style2:disabled {
  background: rgb(36, 38, 38);
  color: #959897;
}
.input-style2::placeholder {
  color: #959897;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.input-style2:focus, textarea {
  outline: none;
}
.label_placeholder2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #A4A4A4
}
//.input-style2:focus-visible {
//  border-bottom: 2px solid #01B574;
//}
.input-style2 + .label_placeholder2 {
  position: absolute;
  top: 17px;
  left: 16px;
  transition: all 0.4s ease;
  pointer-events: none;
}
.my-textarea + .label_placeholder2 {
  position: absolute;
  top: 17px;
  left: 16px;
  transition: all 0.4s ease;
  pointer-events: none;
}

.my-textarea:not(:placeholder-shown) + .label_placeholder2,
.input-wrapper2:focus-within .label_placeholder2 {
  top: 7px;
  font-size: 12px;
  line-height: 14px;
}
.input-wrapper2 {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
}
.input-style2:not(:placeholder-shown) + .label_placeholder2,
.input-wrapper2:focus-within .label_placeholder2 {
  top: 7px;
  font-size: 12px;
  line-height: 14px;
}
@font-face {
  font-family: 'Axiforma Black';
  src: local('Axiforma Black'),
  url(./assets/fonts/Axiforma-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Axiforma";
  src: local("Axiforma"),
  url(./assets/fonts/Axiforma.ttf) format("truetype");
}

@font-face {
  font-family: "Beauty";
  src: local("Beauty"),
  url(./assets/fonts/Darlington.ttf) format("truetype");
}
.width_page {
  padding: 0 120px;
}

.label_valid {
  color: #E50914;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-top: 4px;
  margin-left: 4px;
}
.toastify {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  border-radius: 4px;
  overflow: hidden;

  .toast-close {
    color: #ffffff00;
    position: relative;
    background: none;

    &::after {
      content: url("./assets/icons/close_toast_icon.svg");
      position: absolute;
      right: 4px;
    }
  }
}
.toggle-switch{
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #959897;
    transition: .4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background: #131616;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: red;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
@media (max-width: 1190px) {
  .width_page {
    padding: 0 40px;
  }
}
#image-cropper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  .vue-rectangle-stencil__bounding-box {
    border-radius: 24px;
    overflow: hidden;
  }

  .vue-advanced-cropper__background,
  .vue-advanced-cropper__image-wrapper,
  .vue-advanced-cropper__foreground {
    display: none;
  }

}
@media (max-width: 992px) {
  .width_page {
    padding: 0 16px;
  }
  .input-style {
    height: 48px;
  }
}
</style>
