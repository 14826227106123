<template>
  <div class='width_page'>
    <div class='header'>
      <div class='left_content'>
        <Logo />
        <div class='beta'>BETA</div>
      </div>
      <div class='right_content'>
        <div class='btn_create_login'>
          <a href='https://panageniusnetwork.com/sign-up' class='btn-red' >create free account</a>
          <a href='https://panageniusnetwork.com/login' class='btn-black' >Log in</a>
        </div>
      </div>
      <div @click='toggleMenu()' class='right_content_mobile'>
        <svg class='cp' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
          <path d='M22.5 12.5H1.5' stroke='#7A8080' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' />
          <path opacity='0.7' d='M22.5 4.5L1.5 4.5' stroke='#7A8080' stroke-width='2' stroke-miterlimit='10'
                stroke-linecap='round' />
          <path opacity='0.7' d='M22.5 20.5H1.5' stroke='#7A8080' stroke-width='2' stroke-miterlimit='10'
                stroke-linecap='round' />
        </svg>
      </div>
    </div>

  </div>
  <div class='mob_menu' :class="{ 'mob_menu--open': isMenuOpen }">
    <ul class='mob-prof-menu'>
      <li><a href='https://panageniusnetwork.com/sign-up'>Create account</a></li>
      <li><a href='https://panageniusnetwork.com/login'>Log in</a></li>
    </ul>
  </div>
</template>

<script>

import Logo from '@/components/svg/logo.vue';


export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header',
  components: { Logo },
  data() {
    return {
      dropdownOpen: false,
      isMenuOpen: false,
    };
  },
  mounted() {
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
  computed: {},
};
</script>

<style lang='scss' scoped>
.header {
  background: #131616;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #272b2b;
}

.left_content {
  display: flex;
  align-items: center;
}

.separator {
  margin-left: 36px;
  margin-right: 24px;
}

.right_content {
  display: flex;
  gap: 16px;
  align-items: center;
}

.right_content_mobile {
  display: none;
}

.list_items {
  display: flex;
  gap: 40px;
}

.item_header {
  gap: 8px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;

  & span {
    color: #959897;
  }

  & .item_explore {
    color: #F3F6F4;
  }
}

.down_svg {
  padding-bottom: 2px;
}

.btn_create_login {
  display: flex;
  gap: 16px;
  align-items: center;
}

.btn_icon {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}

.btn_icon:hover {
  border-radius: 12px;
  border: 2px solid #222626;

  svg path {
    fill: #B4B8B6;
  }
}

.right_menu {
  display: flex;
}

.box_avatar {
  position: relative;
  padding: 4px;
  width: 24px;
  height: 24px;
}

.box_avatar_pre:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  border: 2px solid transparent;
  background: linear-gradient(120deg, #B3A100, #222626) border-box;
  -webkit-mask: linear-gradient(#222626 0 0) padding-box, linear-gradient(#222626 0 0);
  mask-composite: exclude;
}

.box_avatar_standard:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;

  border: 2px solid transparent;
  background: linear-gradient(120deg, #4485f7, #222626) border-box;
  -webkit-mask: linear-gradient(#222626 0 0) padding-box, linear-gradient(#222626 0 0);
  mask-composite: exclude;
}

.box_avatar_free:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;

  border: 2px solid transparent;
  background: linear-gradient(120deg, #f4f6f4, #222626) border-box;
  -webkit-mask: linear-gradient(#222626 0 0) padding-box, linear-gradient(#222626 0 0);
  mask-composite: exclude;
}

.avatar_header {
  width: 24px;
  height: 24px;
  border-radius: 7px;
  object-fit: cover;
}

.user-prof-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  top: 40px;
  left: -170px;
  border: 1px solid #2F3737;
  background: #222626;
  position: absolute;
  z-index: 4;
  border-radius: 4px;
  min-width: 200px;
}

.user_box_menu {
  position: relative;
}

.user-prof-menu li a {
  display: block;
  padding: 12px 16px;
  text-decoration: none;
  color: #959897;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: end;
}

.user-prof-menu li a:hover {
  background-color: rgba(49, 49, 49, 0.76);
  color: #e3e3e3;
}

.user-prof-menu li hr {
  margin: 4px 0;
  border-color: #111111;
}

.nav-link {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.up_svg_down {
  transform: rotate(180deg);
}

.left_content a {
  display: flex;
}

.mob_menu {
  position: fixed;
  top: -100%;
  left: 0;
  right: 0;
  transition: top 0.5s ease;
}

.mob_menu--open {
  top: 80px;
  z-index: 3;
}

.mob-prof-menu {
  z-index: 4;
  list-style: none;
  padding: 0;
  margin: 0;
  background: #141616;
  width: 100%;
}

.mob-prof-menu li a {
  display: block;
  padding: 12px 16px;
  text-decoration: none;
  color: #959897;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.beta {
  margin-left: 20px;
  background: #b4b8b6;
  height: 26px;
  border-radius: 24px;
  font-size: 13px;
  font-weight: 900;
  padding: 1px 16px 0 16px;
  display: flex;
  align-items: center;
}

.plan_user {
  font-family: 'Axiforma Black', sans-serif;
  position: absolute;
  font-weight: 900;
  font-size: 6px;
  line-height: 11px;
  width: 20px;
  height: 10px;
  display: inline-block;
  text-align: center;
  border: 1px solid rgba(19, 22, 22, 1);
  border-radius: 8px;
  right: 0;
  bottom: 0;
  z-index: 5;
}

.plan_premium {
  background: #f0d801;
}

.plan_standard {
  background: rgba(67, 133, 247, 1);
}

@media (max-width: 992px) {
  .right_content {
    display: none;
  }
  .right_content_mobile {
    display: flex;
  }
  .list_items {
    display: none;
  }
}

</style>
