<template>
  <div class='container_invitation'>
    <input id='email' v-model='email'  class='input-style' placeholder=' ' type='text'>
    <label for='email' >Email address</label>
    <button class='btn-red' @click='registrationUser'>{{btn_label}}</button>
  </div>
  <div v-if='is_checkbox' class='text_c mt16 keep_releases'>
    <input type="checkbox"  class='custom-checkbox' checked>
    Keep me in the loop with latest releases.
  </div>
  <div :style='{width: width_input==="272px"?"272px":""}' class='label mt8'> By sharing your e-mail, you agree to our <a href='/terms-of-service' class='text_gray'>Terms of Service</a> and <a href='/privacy-policy' class='text_gray'>Privacy Policy</a>.</div>

</template>
<script>

export default {
  name: "InvitationRegistration",
  components: {  },
  props:{
    width_input:{
      type: String,
      default:'398px'
    },
    is_checkbox:{
      default:true
    },
    btn_label:{
      type: String,
      default:'create account'
    }
  },
  data(){
    return {
      email:''
    }
  },
  methods:{
    registrationUser(){
      window.location.href='https://panageniusnetwork.com/sign-up'
    }
  }

};
</script>

<style scoped lang='scss'>
.mt16{
  margin-top: 16px;
}
.mt8{
  margin-top: 8px;
}
.input-style {
 // width: 398px;
  width: 100%;
}

.container_invitation {
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
  position: relative;
}
.container_invitation label{
  color: #959897;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.label{
  color: #959897;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.text_c{
  color: #959897;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;
}
.text_gray{
 color: #B4B8B6
}
.keep_releases{
  display: flex;
  align-items: center;
  gap: 8px;
}

.custom-checkbox {
  width: 16px;
  height: 16px;
}

input[type='checkbox'] {
  width: 16px;
  height: 16px;
  background-color: #E50914;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  position: relative;
  border-radius: 4px;
}

input[type='checkbox']:checked::after {
  content: '';
  position: absolute;
  left: 5px;
  top: 3px;
  width: 3px;
  height: 6px;
  border: solid black;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.input-style + label {
  position: absolute;
  top: 16px;
  left: 16px;
  transition: all 0.4s ease;
  pointer-events: none;
  padding: 0 5px;
}

.input-style:not(:placeholder-shown) + label,
.container_invitation:focus-within label {
  top: -12px;
  background: #141616;
}

//.input-style:placeholder-shown + label {
//  // Styles to keep the label as placeholder...
//}
@media (max-width: 992px) {
  .input-style {
    width: 100%;
  }
  .container_invitation {
    display: flex;
    gap: 16px;
    align-items: normal;
    width: 100%;
    flex-direction: column;
  }
}
</style>