<template>
  <main-layout>
    <div class='home_page'>
      <div class='video_preview_box'>
        <div class='description_video'>
          <div class='text_desc'>Home to <span class='red_text'>Premium</span> </div>
          <div class='text_desc max_w500'>Video Content from the Afro-Diaspora</div>
          <div class='text_sp '>Discover exciting shows, video courses, events and more!</div>
          <div class='invitation_registration'>
            <InvitationRegistration btn_label='create free account' />
          </div>
        </div>
        <div class='gradient_video_box'></div>
        <img class='img_gradient_video_box' src='@/assets/img/gradient_b1.png' alt=''>
        <div class='video_container'>
          <video class='video' playsinline autoplay muted loop ref='videoPlayer'  @ended='endedVideo'>
            <source :src='currentVideo' type='video/mp4'>
          </video>
        </div>
      </div>
      <div class='switch_tab'>
        <switch-tab @selected_tab='selectedTabVal' />
      </div>
      <MentorCardsContainer v-if='courses?.length' :courses='courses'/>
      <div class='inline_info'>
        <div class='separator_long'>
          <separator-long />
        </div>
        <div class=''>
          <p class="text__inline_info">PanaGenius is the authoritative VOICE of the modern Afro-Diaspora committed to shaping and amplifying our narratives. <a href='https://www.panagenius.com/' target="_blank" class="text_g cp">Learn more about us.</a></p>
        </div>
        <div class='separator_long'>
          <separator-long />
        </div>
      </div>
      <VideoCarousel scroll-direction='left' />
      <div class='box_explore'>
        <div class='explore'>
          <div class='title_explore'>Explore the <span class='red_text'>Magic</span>.</div>
          <div class='text_explore'>Access culturally relevant video content designed to offer you expert insights, <br /> entrepreneurial wisdom, and a dose of inspiration for life and career success.
          </div>
          <div class='btn-red' @click='signUp'>get started now
            <svg class='mr8' xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'>
              <path opacity='0.4'
                    d='M5.96832 8.7935L3.50217 9.0116C2.94872 9.0116 2.5 8.5585 2.5 7.99966C2.5 7.44082 2.94872 6.98773 3.50217 6.98773L5.96832 7.20583C6.4025 7.20583 6.75449 7.56126 6.75449 7.99966C6.75449 8.4388 6.4025 8.7935 5.96832 8.7935Z'
                    fill='white' />
              <path
                d='M13.2218 8.38977L13.2093 8.4024L13.1972 8.41546C12.8809 8.7584 12.2437 9.27108 11.5137 9.76872C10.7848 10.2656 10.072 10.6734 9.6359 10.8467L9.62787 10.8498L9.6199 10.8532C9.57038 10.8738 9.45745 10.9161 9.33595 10.9531C9.27659 10.9712 9.22464 10.9851 9.18402 10.9941C9.17752 10.9956 9.17184 10.9968 9.16694 10.9978C9.13686 10.9943 9.10627 10.9863 9.07283 10.9718C9.03729 10.9476 9.00865 10.9149 8.9906 10.881C8.98564 10.8645 8.97929 10.8419 8.97172 10.8132C8.95622 10.7543 8.93939 10.684 8.92331 10.6131C8.90742 10.543 8.89339 10.4774 8.88351 10.4295C8.88 10.4126 8.87718 10.3987 8.87509 10.3881L8.87005 10.3607C8.78122 9.87761 8.72363 9.01714 8.72363 8.00441C8.72363 7.07985 8.77671 6.25916 8.85022 5.73809C8.85186 5.73056 8.85323 5.7241 8.85424 5.71929C8.85927 5.69532 8.86537 5.66454 8.87096 5.63632L8.87239 5.62915C8.88545 5.56327 8.90148 5.48281 8.92016 5.39776C8.93915 5.3113 8.95861 5.23066 8.97711 5.16568C8.98603 5.13432 8.99245 5.11484 8.99582 5.10496C9.03895 5.03446 9.10545 5.00267 9.16024 5.00016C9.17065 5.00222 9.18917 5.00616 9.21687 5.01333C9.27903 5.02942 9.35405 5.05296 9.43167 5.0801C9.46061 5.09022 9.48841 5.10031 9.5143 5.10997L9.71415 5.19383C10.1348 5.37033 10.8295 5.76789 11.5388 6.24979C12.2523 6.73451 12.8796 7.23618 13.2036 7.59072L13.2193 7.60789L13.2353 7.6239L13.2354 7.62395L13.2355 7.62409L13.2356 7.62419L13.2357 7.62425L13.2357 7.62431L13.2361 7.62471L13.2411 7.62975L13.2628 7.65178C13.2818 7.67133 13.308 7.69853 13.3363 7.72891C13.365 7.75979 13.3929 7.7907 13.4164 7.81814C13.4362 7.84136 13.4461 7.85415 13.4487 7.85745C13.4495 7.85849 13.4495 7.85858 13.449 7.85776L13.4597 7.87316L13.471 7.88815C13.4831 7.90417 13.5003 7.94079 13.5003 7.99486C13.5003 8.0515 13.4879 8.09229 13.4709 8.12272C13.4693 8.12453 13.4677 8.12624 13.4662 8.12785L13.4648 8.12938L13.3898 8.21161C13.3343 8.27232 13.2752 8.33586 13.2218 8.38977Z'
                fill='white' stroke='white' stroke-width='2' />
            </svg>
          </div>
        </div>
      </div>
      <VideoCarousel scroll-direction='right' />
      <div class='inline_info'>
        <div class='separator_long'>
          <separator-long />
        </div>
        <div class='text__inline_info'>Ready to start?&nbsp; <span class='text_g cp' @click='signUp' >Sign up to begin
        </span>
          &nbsp; watching anytime, anywhere, on mobile or desktop.
        </div>
        <div class='separator_long'>
          <separator-long />
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>

import MainLayout from "@/layouts/MainLayout.vue";
import InvitationRegistration from "@/components/InvitationRegistration.vue";
import SwitchTab from "@/components/SwitchTab.vue";
import SeparatorLong from "@/components/svg/separatorLong.vue";
import VideoCarousel from "@/components/VideoCarousel.vue";
import http from "../services/http_client";
import { baseErrHandler, getFilteredCourses } from "@/services/api";
import MentorCardsContainer from "@/components/MentorCardsContainer.vue";

export default {
  name: "LandingPage",
  components: {
    MentorCardsContainer,
    VideoCarousel,
    SeparatorLong,
    SwitchTab,
    InvitationRegistration,
    MainLayout
  },
  data() {
    return {
      ended_video: false,
      courses: [],
      isMobile: false,
    };
  },
  mounted() {
    this.checkLoggedUser()
    this.playVideo();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    this.getCourses();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    checkLoggedUser(){
      const user = JSON.parse(localStorage.getItem('panagenius_user'));
      const jwt = localStorage.getItem('panagenius_jwt');
      if (user&&jwt){
        window.location.href='https://panageniusnetwork.com/dashboard'
      }
    },
    playVideo() {
      const videoPlayer = this.$refs.videoPlayer;
      if (videoPlayer) {
        videoPlayer.play()
      }
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    signUp(){
      window.location.href='https://panageniusnetwork.com/sign-up'
    },
    async selectedTabVal(val) {
      this.$nextTick(  () => {
        this.courses = []
      });
      await this.getCourses(val);
    },
    async getCourses(category) {
      await baseErrHandler(async () => {
        const { courses } = await getFilteredCourses({category});
        this.courses = courses;
      });
    },
    getVideo(video) {
      return http.uploadsUrl() + "uploads/courses/" + video;
    },
    endedVideo() {
      this.ended_video = true;
      let videoPlayer = this.$refs.videoPlayer;
      if (videoPlayer?.currentTime) {
        videoPlayer.currentTime = 0;
        videoPlayer.play();
      }
    }
  },
  computed: {
    currentVideo() {
      return `${http.uploadsUrl()}uploads/courses/${this.isMobile ? "7266037413642934625cover_video_update.MP4" : "4728146734004766653cover_video_update_2.MP4"}`;
    },
  }
};
</script>
<style lang='css' scoped>
.home_page {
  display: flex;
  flex-direction: column;
}

.video_container {
  display: flex;
  justify-content: right;
}

.video_preview_box {
  position: relative;
}

.video {
  height: 552px;
  border-radius: 16px;
}

.gradient_video_box {
  position: absolute;
  background: linear-gradient(90deg, #131616 36.67%, rgba(19, 22, 22, 0.00) 65.07%);
  width: -webkit-fill-available;
  height: 552px;
  flex-shrink: 0;
}

.description_video {
  display: flex;
  flex-direction: column;
  position: absolute;
  transform: translate(0%, -50%);
  top: 50%;
  left: 0;
  z-index: 3;
}

.text_desc {
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 50px;
  letter-spacing: 1.876px;
  color: #FFF;
}

.red_text {
  background: linear-gradient(270deg, #E50914 67.18%, #FF4550 98.47%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.invitation_registration {
  margin-top: 32px;
  width: 444px;
  box-sizing: border-box;
}

.all_classes {
  color: #E50914;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 30px;
}

.arrow_right {
  margin-left: 8px;
}

.switch_tab {
  margin-top: 38px;
  margin-bottom: 24px;
}


.inline_info {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 19px;
  margin: 64px 0;
}

.separator_long {
  width: 100%;
  display: flex;

}

.text__inline_info {
  color: #BABABA;
  font-size: 16px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.2px;
  align-items: center;
}

.text_g {
  font-size: 16px;
  font-style: normal;
  color: #cecece;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-decoration-line: underline;
  background: linear-gradient(100deg, #E6E6E6 -4.37%, #BABABA 61.43%);
  background-clip: text;
}

.arrow_right__inline_info {
  transform: rotate(90deg);
}

.title_explore {
  color: #FFF;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
}

.text_explore {
  color: #959897;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.mr8 {
  margin-right: 8px;
}

.box_explore {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
}

.explore {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.explore .btn-red {
  margin-top: 16px;
}

.img_gradient_video_box {
  display: none;
}

.gradient__card {
  opacity: 0.92;
  background: #131616;
  position: absolute;
  right: -120px;
  top: 0;
  z-index: 1;
  width: 108px;
  height: 100%;
  background: linear-gradient(90deg, rgba(22, 24, 24, 0) 0, rgba(22, 24, 24, 1) 17.37%, #161818 100%);
}

.gradient__card_left {
  opacity: 0.92;
  background: #131616;
  position: absolute;
  left: -120px;
  top: 0;
  width: 108px;
  height: 100%;
  z-index: 5;
  background: linear-gradient(270deg, rgba(22, 24, 24, 0) 0, rgba(22, 24, 24, 1) 17.37%, #161818 100%);
}

.container_mentor {
  position: relative;
}
.text_sp{
  color: #959897;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin-top: 16px;
}
.max_w500{
  max-width: 500px;
}
@media (max-width: 992px) {

  .invitation_registration {
    width: 100%;
  }

  .video {
    width: 100%;
    height: 201px;
    border-radius: 16px 16px 8px 8px;
  }

  .all_classes {
    display: none;
  }

  .img_gradient_video_box {
    display: flex;
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    bottom: 0;
  }

  .gradient_video_box {
    display: none;
    width: 343px;
    transform: rotate(-90deg);
    height: 100%;
    background: linear-gradient(90deg, #131616 0%, rgba(19, 22, 22, 0.00) 51.45%);
  }

  .description_video {
    top: 199%;
  }

  .text_desc {
    font-size: 28px;
    line-height: 35px;
  }

  .invitation_registration {
    margin-top: 8px;
  }

  .video_preview_box {
    position: relative;
    margin-bottom: 250px;
  }

  .text__inline_info {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }

  .title_explore {
    font-size: 30px;
    line-height: 38px;
  }
  .switch_tab {
    margin-top: 85px;
  }
}


</style>
